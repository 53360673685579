import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_MICROSOFT_USER,
  SIGNIN_MICROSOFT_USER_SUCCESS,
  LINK_MICROSOFT_USER,
  LINK_MICROSOFT_USER_SUCCESS,
  SIGNIN_TWITTER_USER,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS
} from 'constants/ActionTypes';

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = (authUser, displayName) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser,
    displayName: displayName
  };
};

export const userSignInSuccess = (authUser, displayName, isMicrosoftLinked) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
    displayName: displayName,
    isMicrosoftLinked: isMicrosoftLinked,
  }
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};


export const userGoogleSignIn = () => {
  return {
    type: SIGNIN_GOOGLE_USER
  };
};
export const userGoogleSignInSuccess = (authUser, displayName) => {
  return {
    type: SIGNIN_GOOGLE_USER_SUCCESS,
    payload: authUser,
    displayName: displayName
  };
};
export const userFacebookSignIn = () => {
  return {
    type: SIGNIN_FACEBOOK_USER
  };
};
export const userMicrosoftSignIn = () => {
  return {
    type: SIGNIN_MICROSOFT_USER
  };
};
export const userMicrosoftSignInSuccess = (authUser, displayName) => {
  return {
    type: SIGNIN_MICROSOFT_USER_SUCCESS,
    payload: authUser,
    displayName: displayName
  };
};
export const userMicrosoftLink = () => {
  return {
    type: LINK_MICROSOFT_USER
  };
};
export const userMicrosoftLinkSuccess = () => {
  return {
    type: LINK_MICROSOFT_USER_SUCCESS,
  };
};
export const userFacebookSignInSuccess = (authUser, displayName) => {
  return {
    type: SIGNIN_FACEBOOK_USER_SUCCESS,
    payload: authUser,
    displayName: displayName
  };
};
export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const userTwitterSignIn = () => {
  return {
    type: SIGNIN_TWITTER_USER
  };
};
export const userTwitterSignInSuccess = (authUser, displayName) => {
  return {
    type: SIGNIN_TWITTER_USER_SUCCESS,
    payload: authUser,
    displayName: displayName
  };
};
export const userGithubSignIn = () => {
  return {
    type: SIGNIN_GITHUB_USER
  };
};
export const userGithubSignInSuccess = (authUser, displayName) => {
  return {
    type: SIGNIN_GITHUB_USER_SUCCESS,
    payload: authUser,
    displayName: displayName
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};
export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
