import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  auth,
  facebookAuthProvider,
  githubAuthProvider,
  googleAuthProvider,
  twitterAuthProvider,
  microsoftAuthProvider
} from "firebase/firebaseConfig";
import {
  SIGNIN_FACEBOOK_USER,
  SIGNIN_GITHUB_USER,
  SIGNIN_GOOGLE_USER,
  SIGNIN_MICROSOFT_USER,
  LINK_MICROSOFT_USER,
  SIGNIN_TWITTER_USER,
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER
} from "constants/ActionTypes";
import {showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "actions/Auth";
import {
  userFacebookSignInSuccess,
  userGithubSignInSuccess,
  userGoogleSignInSuccess,
  userTwitterSignInSuccess,
  userMicrosoftSignInSuccess,
  userMicrosoftLinkSuccess,
} from "../actions/Auth";

const createUserWithEmailPasswordRequest = async (email, password) =>
  await  auth.createUserWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await  auth.signInWithEmailAndPassword(email, password)
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async () =>
  await  auth.signOut()
    .then(authUser => authUser)
    .catch(error => error);


const signInUserWithGoogleRequest = async () =>
  await  auth.signInWithPopup(googleAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithMicrosoftRequest = async () =>
  await auth.signInWithPopup(microsoftAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const userLinkWithMicrosoftRequest = async () =>
  await auth.currentUser.linkWithPopup(microsoftAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithFacebookRequest = async () =>
  await  auth.signInWithPopup(facebookAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithGithubRequest = async () =>
  await  auth.signInWithPopup(githubAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithTwitterRequest = async () =>
  await  auth.signInWithPopup(twitterAuthProvider)
    .then(authUser => authUser)
    .catch(error => error);

function* createUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      localStorage.setItem('displayName', email);
      yield put(userSignUpSuccess(signUpUser.user.uid, email));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithGoogle() {
  try {
    const signUpUser = yield call(signInUserWithGoogleRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      localStorage.setItem('displayName', signUpUser.user.displayName);
      yield put(userGoogleSignInSuccess(signUpUser.user.uid, signUpUser.user.displayName));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithMicrosoft() {
  try {
    const signUpUser = yield call(signInUserWithMicrosoftRequest);
    var whitelist = [
      'lim@sher-cpa.com',
      'ldomine@sher-cpa.com',
      'nick@sher-cpa.com',
      'luciano@sher-cpa.com',
      'kevin@leyow.onmicrosoft.com'
    ]
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else if (whitelist.includes(signUpUser.additionalUserInfo.profile.mail)) {
      localStorage.setItem('user_id', signUpUser.user.uid);

      var displayName;
      if (signUpUser.user.email) {
        displayName = signUpUser.user.email;
      } else if ((signUpUser.user.displayName)) {
        displayName = signUpUser.user.displayName;
      } else {
        displayName = signUpUser.additionalUserInfo.profile.mail;
      }

      localStorage.setItem('displayName', displayName);
      yield put(userMicrosoftSignInSuccess(signUpUser.user.uid, displayName));
    } else if (!(whitelist.includes(signUpUser.user.email))){
      // Delete account from auth if it wasn't in whitelist.
      signUpUser.user.delete();
      throw "Unauthorized Microsoft Account.";
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* linkMicrosoftAccount() {
  try {
    const linkUpUser = yield call(userLinkWithMicrosoftRequest);
    var whitelist = [
      'lim@sher-cpa.com',
      'ldomine@sher-cpa.com',
      'nick@sher-cpa.com',
      'luciano@sher-cpa.com',
      'kevin@leyow.onmicrosoft.com'
    ]
    if (linkUpUser.message) {
      yield put(showAuthMessage(linkUpUser.message));
    } else if (whitelist.includes(linkUpUser.user.email)) {
      yield put(userMicrosoftLinkSuccess());
    } else if (!(whitelist.includes(linkUpUser.user.email))){
      // Delete account from auth if it wasn't in whitelist.
      linkUpUser.user.delete();
      throw "Unauthorized Microsoft Account.";
    }
  } catch (error) {
    if (error.code == "auth/provider-already-linked") {
      yield put(userMicrosoftLinkSuccess());
    }
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithFacebook() {
  try {
    const signUpUser = yield call(signInUserWithFacebookRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      localStorage.setItem('displayName', signUpUser.user.displayName);
      yield put(userFacebookSignInSuccess(signUpUser.user.uid, signUpUser.user.displayName));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithGithub() {
  try {
    const signUpUser = yield call(signInUserWithGithubRequest);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      localStorage.setItem('displayName', signUpUser.user.displayName);
      yield put(userGithubSignInSuccess(signUpUser.user.uid, signUpUser.user.displayName));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}


function* signInUserWithTwitter() {
  try {
    const signUpUser = yield call(signInUserWithTwitterRequest);
    if (signUpUser.message) {
      if (signUpUser.message.length > 100) {
        yield put(showAuthMessage('Your request has been canceled.'));
      } else {
        yield put(showAuthMessage(signUpUser.message));
      }
    } else {
      localStorage.setItem('user_id', signUpUser.user.uid);
      localStorage.setItem('displayName', signUpUser.user.displayName);
      yield put(userTwitterSignInSuccess(signUpUser.user.uid, signUpUser.user.displayName));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);

    if (signInUser.message) {
      yield put(showAuthMessage(signInUser.message));
    } else {
      var isMicrosoftLinked = false
      signInUser.user.providerData.forEach(function(userInfo){
        if (userInfo.providerId == "microsoft.com") {
          isMicrosoftLinked = true;
        }
      });

      localStorage.setItem('user_id', signInUser.user.uid);
      localStorage.setItem('displayName', email);
      yield put(userSignInSuccess(signInUser.user.uid, email, isMicrosoftLinked));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    if (signOutUser === undefined) {
      localStorage.removeItem('user_id');
      localStorage.removeItem('displayName');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_GOOGLE_USER, signInUserWithGoogle);
}

export function* signInWithMicrosoft() {
  yield takeEvery(SIGNIN_MICROSOFT_USER, signInUserWithMicrosoft);
}

export function* linkWithMicrosoft() {
  yield takeEvery(LINK_MICROSOFT_USER, linkMicrosoftAccount);
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_FACEBOOK_USER, signInUserWithFacebook);
}

export function* signInWithTwitter() {
  yield takeEvery(SIGNIN_TWITTER_USER, signInUserWithTwitter);
}

export function* signInWithGithub() {
  yield takeEvery(SIGNIN_GITHUB_USER, signInUserWithGithub);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(createUserAccount),
    fork(signInWithGoogle),
    fork(signInWithMicrosoft),
    fork(linkWithMicrosoft),
    fork(signInWithFacebook),
    fork(signInWithTwitter),
    fork(signInWithGithub),
    fork(signOutUser)]);
}
