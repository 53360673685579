import { MICROSOFT_360_LOGIN_ENABLED } from 'constants/Constants';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import {connect} from 'react-redux'
import {
  userSignOut,
  hideMessage,
  showAuthLoader,
  userMicrosoftLink
} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import {Dropdown, DropdownToggle} from 'reactstrap';
import ColorOption from '../../containers/Customizer/ColorOption';



class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    console.log(this.props)
    console.log(this.state)
  }

  handleClick = event => {
    this.setState({open: true, anchorEl: event.currentTarget});
  };

  handleRequestClose = () => {
    this.setState({open: false});
  };

  render() {
    const {authUser, isMicrosoftLinked, showMessage, alertMessage} = this.props;
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <ul className="header-notifications list-inline ml-auto">
          <li className="list-inline-item">
          <Dropdown
            className="quick-menu app-notification"
            toggle={this.handleClick}>

            <DropdownToggle
              className="d-inline-block"
              tag="span"
              data-toggle="dropdown">
              <span className="badge display-4" style={{padding: '5px', backgroundColor: 'rgba(0, 0, 0, 0.15)', fontSize: 'small' }}>
                <span className="p-2">{this.props.displayName}</span>
                <i className="zmdi zmdi-caret-down zmdi-hc-fw zmdi-hc-lg"/>
              </span>
            </DropdownToggle>
          </Dropdown>
          </li>
        </ul>

        <Menu className="user-info"
              id="simple-menu"
              anchorEl={this.state.anchorEl}
              open={this.state.open}
              onClose={this.handleRequestClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              PaperProps={{
                style: {
                  minWidth: 120,
                  paddingTop: 0,
                  paddingBottom: 0
                }
              }}>
          <ColorOption/>

          <MenuItem onClick={() => {
            this.handleRequestClose();
            this.props.userSignOut()
          }}>
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

            <IntlMessages id="popup.logout"/>
          </MenuItem>
          {!isMicrosoftLinked && MICROSOFT_360_LOGIN_ENABLED ?
            <MenuItem onClick={() => {
              this.handleRequestClose();
              this.props.userMicrosoftLink();
            }}>
              <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
              Link Microsoft Account
            </MenuItem> : null
          }
        </Menu>
        {showMessage && NotificationManager.info(alertMessage)}
        <NotificationContainer/>
      </div>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {locale} = settings;
  const {loader, alertMessage, showMessage, authUser, displayName, isMicrosoftLinked} = auth;
  return {locale, loader, alertMessage, showMessage, authUser, displayName, isMicrosoftLinked}
};

export default connect(mapStateToProps, {userSignOut, userMicrosoftLink, showAuthLoader, hideMessage})(UserInfo);
